import React, { Fragment, useEffect, useState } from "react";
import NavBar from "../NavBar/NavBar";
import { setActiveNavbarElement } from "../../helpers/actions";
import AOS from "aos";
import Footer from "../Footer/Footer";
import Section from "./Section";
import OurProducts from "./OurProducts";
import AboutProduct from "./AboutProduct";
import ProductDetails from "./ProductDetails";

const Index = () => {

  setActiveNavbarElement(4)
  useEffect(() => {
    AOS.init();
  }, []);


  return (
    <Fragment>
      <NavBar />
      <Section/>
      <OurProducts />
      {/* <ProductDetails/> */}
      {/* <AboutProduct/> */}
      <Footer/>
    </Fragment>
  );
}

export default Index;
