import React from 'react';
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";

const ContactDetails = () => {
    return (
        <div className="title-heading mt-2">
            <h2 >Let's talk about your Requirements..</h2>
            <p className="text-dark">
                Start working with{" "}
                <span className="text-primary fw-bold">
                    Pixel
                </span>{" "}
                that can provide everything you will need .
            </p>
            <div className="d-flex contact-detail align-items-center mt-3">
                <div className="icon">
                
                    <FeatherIcon
                    icon="mail"
                    className="fea icon-m-md text-dark me-3"
                    />
                
                </div>
                <div className="flex-1 content">
                <h5 className="title fw-bold mb-0">Email</h5>
                <Link
                    to="#"
                    // onClick={this.sendMail}
                    className="text-primary"
                >
                    sales@pixel-softwares.com
                </Link>
                </div>
            </div>
            <div className="d-flex contact-detail align-items-center mt-3">
                <div className="icon">
                
                    <FeatherIcon
                    icon="phone"
                    className="fea icon-m-md text-dark me-3"
                    />
                
                </div>
                <div className="flex-1 content">
                <h5 className="title fw-bold mb-0">Phone</h5>
                <Link
                    to="#"
                    // onClick={this.callNumber}
                    className="text-primary"
                >
                    +20-1002517431
                </Link>
                </div>
            </div>
            <div className="d-flex contact-detail align-items-center mt-3">
                <div className="icon">
                
                    <FeatherIcon
                    icon="map-pin"
                    className="fea icon-m-md text-dark me-3"
                    />
                
                </div>
                <div className="flex-1 content">
                <h5 className="title fw-bold mb-0">Location</h5>
                <Link to="#" className="video-play-icon text-primary">
                    Maadi - Cairo - Egypt
                </Link>
                </div>
            </div>
        </div>
    );
}

export default ContactDetails;
