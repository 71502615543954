import React, { useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';

// import images
import logoDark from '../../assets/images/MainDarkLogo.png';
import logoLight from '../../assets/images/MainLightLogo.png';

const NavBar = () => {

  const activeElement = localStorage.getItem('activePage')

  useEffect(() => {
    const elements = document.querySelectorAll('.navigation-menu .sub-menu-item')
    window.scrollTo(0, 0);
    document.body.classList = '';
    elements[activeElement].classList.add('activeNavbarElement-light')
    window.addEventListener('scroll', ()=>scrollNavigation(elements), true);
    return () => {
      window.removeEventListener('scroll', ()=>scrollNavigation(elements), true);
    };
  }, []);

  const scrollNavigation = (elements) => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      elements[activeElement].classList.add('activeNavbarElement-dark')
      elements[activeElement].classList.remove('activeNavbarElement-light')
      document.getElementById('topnav').classList.add('nav-sticky');
    } else {
      elements[activeElement].classList.add('activeNavbarElement-light')
      elements[activeElement].classList.remove('activeNavbarElement-dark')
      document.getElementById('topnav').classList.remove('nav-sticky');
    }
  };

  const isToogleMenu = () => {
    const isToggle = document.getElementById('isToggle');
    if (isToggle) {
      isToggle.classList.toggle('open');
      var isOpen = document.getElementById('navigation');
      console.log('navgiationId', isOpen);
      if (isOpen.style.display === 'block') {
        isOpen.style.display = 'none';
      } else {
        isOpen.style.display = 'block';
      }
    }
  };

  return (
    <Fragment>
      <header id="topnav" className="defaultscroll sticky">
        <div className="container align-items=center">
          <div>
            <Link className="logo" to="/">
              <span className="logo-light-mode">
                <img src={logoDark} className="l-dark" height="37.2" alt="" />
                <img src={logoLight} className="l-light" height="40" alt="" />
              </span>
              <img
                src={logoLight}
                height="37.2"
                className="logo-dark-mode"
                alt=""
              />
            </Link>
          </div>
          <div className="menu-extras">
            <div className="menu-item">
              <Link
                to="#"
                className="navbar-toggle"
                id="isToggle"
                onClick={isToogleMenu}
              >
                <div className="lines">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </Link>
            </div>
          </div>
          <div id="navigation">
            <ul className="navigation-menu nav-light">
              <li><Link to="/" className="sub-menu-item fs-6">Home</Link></li>
              <li><Link to="/about" className="sub-menu-item fs-6">About Us</Link></li>
              <li><Link to="/services" className="sub-menu-item fs-6">Services</Link></li>
              <li><Link to="/products" className="sub-menu-item fs-6">Products</Link></li>
              <li><Link to="/contact" className="sub-menu-item fs-6">Contact Us</Link></li>
            </ul>
          </div>
            {/* <DarkWhiteSwitcher/> */}
        </div>
      </header>
    </Fragment>
  );
};

export default NavBar;
