import React, { Fragment } from "react";
import { Col, Card, CardBody, CardImg } from "reactstrap";

const BlogBox = ({cards = [] , height = '480px'}) => {
    return (
      <Fragment>
        {cards.map((card, key) => (
          <Col lg="4" md="6" className="mt-4 pt-2" key={key} name="blog">
            <Card className="blog rounded border-0 shadow" style={{height:height}}>
              <div className="position-relative">
                <CardImg top src={card.image} className="rounded-top" alt="" width={360} height={250}/>
                <div className="overlay rounded-top bg-dark"></div>
              </div>
              <CardBody className="content">
                <h5 className="title text-dark text-center">
                  {card.title}
                </h5>
                <p className="text-muted text-center">
                  {card.desc}
                </p>
              </CardBody>
              
            </Card>
          </Col>
        ))}
      </Fragment>
    );
}

export default BlogBox;
