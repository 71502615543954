import React, { Fragment } from 'react';
import Client from './Cilent';
import NavBar from '../NavBar/NavBar';
import AboutUs from './AboutUs';
import Blog from '../../pages/ITSolution/Blog';
import Section from './Section';
import { setActiveNavbarElement } from '../../helpers/actions';
import Footer from '../Footer/Footer';
import Team from './Team';

const About = () => {

    setActiveNavbarElement(2)

    return (
        <Fragment>
            <NavBar />
            <Section />
            <section className="section">
                <AboutUs />
                <Client />
                <Team/>
            </section>
            <Footer />
        </Fragment>
    );
}

export default About;
