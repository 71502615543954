import React, { Fragment } from 'react'
import { Card, Col, Container, Row } from 'reactstrap';
import img from "../../assets/images/AboutUs2.png"

const AboutUs = () => {
    return (
        <Fragment>
            <Container>
                <Row className="align-items-center justify-content-between">
                    <Col lg={6} md={6}>
                        <Card className="rounded border-0 shadow-md">
                            <img src={img} className='w-100' height={400} alt="" />
                        </Card>
                    </Col>
                    <Col lg={6} md={6} className="mt-4">
                        <div className="ms-lg-4">
                            <div className="section-title">
                                <span className="badge bg-soft-primary rounded-pill fw-bold">About Pixel</span>
                                <h4 className="title mb-4 mt-3">We design and develop <br /> world-class web applications.</h4>
                                <p className="text-muted para-desc">
                                    At Pixel Software, we're a team of minds driven by a shared passion
                                    for innovation , our team comprises a group of young individuals
                                    with diverse backgrounds. Some of us bring coding expertise to the
                                    table, while others offer invaluable insights gained from working
                                    directly in factories.
                                    Our Core Business is about using technology to simplify processes,
                                    boost efficiency, and enhance safety in factories worldwide
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
    }

export default AboutUs ;
