import React, { Fragment, useEffect } from "react";
import Section from "./Section";
import KeyFeature from "./KeyFeature";
import NavBar from "../NavBar/NavBar";
import Footer5 from "../../pages/Pages/PageFooterLayouts/Footer5";
import { setActiveNavbarElement } from "../../helpers/actions";
import AOS from "aos";
import Cards from "../SharedComponents/Cards";

const Index = () => {

  setActiveNavbarElement(1)
  useEffect(() => {
    AOS.init();
  }, []);

  const cards = [
    {
      id: 1,
      image: require ('../../assets/images/Card1.jpg'),
      title: "Industry Expertise",
    },
    {
      id: 2,
      image: require ('../../assets/images/Card2.jpg'),
      title: "Cutting-Edge Technology",
    },
    {
      id: 3,
      image: require ('../../assets/images/Card3.jpg'),
      title: "Adherence To Standards",
    },
  ];

  return (
    <Fragment>
      <NavBar />
      <Section />
      <Cards cards={cards} height={'350px'}/>
      <KeyFeature />
      <Footer5 />
    </Fragment>
  );
}

export default Index;
