import React, { Fragment } from 'react';
import NavBar from '../NavBar/NavBar';
import { setActiveNavbarElement } from '../../helpers/actions';
import Section from './Section';
import Contact from './Contact';
import Footer from '../Footer/Footer';

const ContactUS = () => {

    setActiveNavbarElement(5)

    return (
        <Fragment>
            <NavBar />
            <Section />
            <Contact/>
            <Footer/>
        </Fragment>
    );
}

export default ContactUS;
