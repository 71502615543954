import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { products } from './ProductsList';

const ProductDetails = ({ activeProduct= 0 }) => {

  const product = products[activeProduct]

    const [isOpen, setIsOpen] = useState();
  return (
    <section className="bg-half-170 d-table w-100 bg-light" id='details'>
          <Container>
            <Row className="mt-5 mt-sm-0 align-items-center">
              <Col lg={6} md={6}>
                <div className="title-heading me-lg-4">
                  <h4 className="display-4 fw-bold mb-3">
                    {" "}
                    {product.title}{" "}
                  </h4>
                  <p className="text-muted para-desc mb-0">
                    {product.desc}
                  </p>
                  <div className="mt-4 pt-2">
                    <a href="#about" className="btn btn-soft-primary m-1">
                      Start Now
                    </a>{" "}
                    <Link
                      to="#"
                      onClick={()=>setIsOpen(true)}
                      className="btn btn-icon btn-pills btn-soft-primary m-1 lightbox"
                    >
                      <FeatherIcon
                        icon="video"
                        className="icons"
                      />
                    </Link>
                    <span className="fw-bold text-uppercase small align-middle ms-1">Watch Now</span>
                  </div>
                </div>
              </Col>

              <Col lg={6} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div className="bg-white rounded-md">
                  <img src={product.img} className="img-fluid mx-auto d-block w-100 h-100" alt="Landrick" />
                </div>
              </Col>
            </Row>
            <ModalVideo
              channel="custom"
              url='http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'
              isOpen={isOpen}
              videoId="99025203"
              onClose={() => setIsOpen(false)}
            />
          </Container>
        </section>
  );
}

export default ProductDetails;
