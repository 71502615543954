import React, { Fragment } from "react";
import { Container, Row } from "reactstrap";
import BlogBox from "./BlogBox";

const Cards = ({ cards = [] , height }) => {
  return (
    <Fragment>
      <section className="section pt-5 pt-sm-0 pt-md-4 bg-light">
        <Container>
          <Row>
            <BlogBox cards={cards} height={height}/>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default Cards;
