// React Basic and Bootstrap
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Alert,
  Form,
  Input,
  Label,
  Card,
  CardBody,
} from "reactstrap";

//Import Icons
import FeatherIcon from "feather-icons-react";

// import images
import contactImg from "../../../assets/images/contact-detail.jpg";

class PageContactThree extends Component {  
  constructor(props) {
    super(props);
    this.state = {
      Contactvisible: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.sendMail.bind(this);
    this.callNumber.bind(this);
  }
  handleSubmit(event) {
    event.preventDefault();
    this.setState({ Contactvisible: true });
  }

  componentDidMount() {
    document.body.classList = "";
    document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

      scrollNavigation = () => {
        var doc = document.documentElement;
        const navBar = document.getElementById("topnav");
        var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
        if (navBar != null) {
          if (top > 80) {
            navBar.classList.add("nav-sticky");
          } else {
            navBar.classList.remove("nav-sticky");
            document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
            document.querySelector(".shoppingbtn")?.classList.remove("btn-light");
            document.querySelector(".settingbtn")?.classList.add("btn-soft-primary");
          }
        }
    };

  sendMail() {
    window.location.href = "mailto:contact@example.com";
  }

  callNumber() {
    window.location.href = "tel:+152534-468-854";
  }

  render() {
    return (
      <React.Fragment>        
        <section className="bg-half-170 bg-light d-table w-100" style={{background: `url(${contactImg}) center center`}}>
            <div className="bg-overlay bg-overlay-white"></div>
            <Container>
                <Row className="mt-5 justify-content-center">
                    <Col lg={12} className="text-center">
                        <div className="pages-heading">
                            <h4 className="title mb-0">Contact Us</h4>
                        </div>
                    </Col>  
                </Row>
            </Container> 
        </section>

        <div className="form-icon position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>

        <section className="section">
          <Container>
            <Row>
              <Col lg={6} md={6} className="mt-4 pt-2">
                <Card className="shadow rounded border-0">
                  <CardBody className="py-5">
                    <h4 className="card-title">Get In Touch !</h4>
                    <div className="custom-form mt-3">
                      <div id="message"></div>
                      <Alert
                        color="info"
                        isOpen={this.state.Contactvisible}
                        toggle={() => {
                          this.setState({
                            Contactvisible: !this.state.Contactvisible,
                          });
                        }}
                      >
                        Contact details send successfully.
                      </Alert>
                      <Form
                        method="post"
                        onSubmit={this.handleSubmit}
                        name="contact-form"
                        id="contact-form"
                      >
                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Your Name <span className="text-danger">*</span>
                              </Label>
                              <div className="form-icon position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="user"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <Input
                                name="name"
                                id="name"
                                type="text"
                                className="form-control ps-5"
                                placeholder="Name :"
                                required
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Your Email{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <div className="form-icon position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="mail"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <Input
                                name="email"
                                id="email"
                                type="email"
                                className="form-control ps-5"
                                placeholder="Email :"
                                required
                              />
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label className="form-label">Subject </Label>
                              <div className="form-icon position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="book"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <Input
                                name="subject"
                                id="subject"
                                type="text"
                                className="form-control ps-5"
                                placeholder="Subject :"
                                required
                              />
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label className="form-label">Comments</Label>
                              <div className="form-icon position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="message-circle"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <textarea
                                name="comments"
                                id="comments"
                                rows="4"
                                className="form-control ps-5"
                                placeholder="Message :"
                              ></textarea>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} className="text-center">
                            <div className="d-grid">
                              <input
                                type="submit"
                                id="submit"
                                name="send"
                                className="submitBnt btn btn-primary btn-block"
                                value="Send Message"
                              />
                            </div>
                            <div id="simple-msg"></div>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={6} md={6} className="ps-md-3 pe-md-3 mt-4 pt-2">
                <div className="title-heading mt-2">
                    <h1 className="heading">Let's talk about your portfolio</h1>
                    <p className="text-dark">
                      Start working with{" "}
                      <span className="text-primary fw-bold">
                        Landrick
                      </span>{" "}
                      that can provide everything you need to generate awareness,
                      drive traffic, connect.
                    </p>

                    <div className="d-flex contact-detail align-items-center mt-3">
                      <div className="icon">
                      
                          <FeatherIcon
                            icon="mail"
                            className="fea icon-m-md text-dark me-3"
                          />
                      
                      </div>
                      <div className="flex-1 content">
                        <h5 className="title fw-bold mb-0">Email</h5>
                        <Link
                          to="#"
                          onClick={this.sendMail}
                          className="text-primary"
                        >
                          contact@example.com
                        </Link>
                      </div>
                    </div>

                    <div className="d-flex contact-detail align-items-center mt-3">
                      <div className="icon">
                      
                          <FeatherIcon
                            icon="phone"
                            className="fea icon-m-md text-dark me-3"
                          />
                      
                      </div>
                      <div className="flex-1 content">
                        <h5 className="title fw-bold mb-0">Phone</h5>
                        <Link
                          to="#"
                          onClick={this.callNumber}
                          className="text-primary"
                        >
                          +152 534-468-854
                        </Link>
                      </div>
                    </div>

                    <div className="d-flex contact-detail align-items-center mt-3">
                      <div className="icon">
                      
                          <FeatherIcon
                            icon="map-pin"
                            className="fea icon-m-md text-dark me-3"
                          />
                      
                      </div>
                      <div className="flex-1 content">
                        <h5 className="title fw-bold mb-0">Location</h5>
                        <Link to="#" className="video-play-icon text-primary">
                          View on Google map
                        </Link>
                      </div>
                    </div>

                    <ul className="list-unstyled social-icon mb-0 mt-4">
                      <li className="list-inline-item">
                        <Link to="#" className="rounded">
                          
                            <FeatherIcon
                              icon="facebook"
                              className="fea icon-sm fea-social"
                            />
                        
                        </Link>
                      </li>{" "}
                      <li className="list-inline-item">
                        <Link to="#" className="rounded">
                          
                            <FeatherIcon
                              icon="instagram"
                              className="fea icon-sm fea-social"
                            />
                        
                        </Link>
                      </li>{" "}
                      <li className="list-inline-item">
                        <Link to="#" className="rounded">
                          
                            <FeatherIcon
                              icon="twitter"
                              className="fea icon-sm fea-social"
                            />
                        
                        </Link>
                      </li>{" "}
                      <li className="list-inline-item">
                        <Link to="#" className="rounded">
                          
                            <FeatherIcon
                              icon="linkedin"
                              className="fea icon-sm fea-social"
                            />
                        
                        </Link>
                      </li>{" "}
                      <li className="list-inline-item">
                        <Link to="#" className="rounded">
                          
                            <FeatherIcon
                              icon="github"
                              className="fea icon-sm fea-social"
                            />
                        
                        </Link>
                      </li>{" "}
                      <li className="list-inline-item">
                        <Link to="#" className="rounded">
                          
                            <FeatherIcon
                              icon="youtube"
                              className="fea icon-sm fea-social"
                            />
                        
                        </Link>
                      </li>{" "}
                      <li className="list-inline-item">
                        <Link to="#" className="rounded">
                          
                            <FeatherIcon
                              icon="gitlab"
                              className="fea icon-sm fea-social"
                            />
                        
                        </Link>
                      </li>
                    </ul>
                  </div>
              </Col>
            </Row>
          </Container>

        </section>
      </React.Fragment>
    );
  }
}
export default PageContactThree;
