import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';

const Section = () => {

    return (
        <Fragment>
            <section className="bg-half-80 d-table w-100"
                style={{
                    background: 'rgb(25,87,151)',
                    background: 'linear-gradient(90deg, rgba(25,87,151,1) 0%, rgba(24,101,165,1) 10%, rgba(24,126,190,1) 28%, rgba(24,136,196,1) 46%, rgba(24,128,191,1) 65%, rgba(24,107,172,1) 83%, rgba(25,86,149,1) 100%)'
                }}
            >
                <Container>
                    <Row className="mt-5 justify-content-center">
                        <Col lg={12} className="text-center">
                            <div className="pages-heading title-heading">
                                <h1 className="text-white title-dark"> Contact Us </h1>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
        </Fragment>
    );
}

export default Section;