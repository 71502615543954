
export const products = [
  {
    id: 1 ,
    title: 'Fire Maintenance',
    img: require('../../assets/images/Fire.png'),
    desc: '',
    about:{
      desc:'here is desc',
      features:[
        'feature 1',
        'feature 2',
        'feature 3',
        'feature 4',
        'feature 5',
        'feature 6',
      ],
      images:[
        require('../../assets/images/AboutUs.jpeg'),
        require('../../assets/images/1.jpg'),
        require('../../assets/images/2.jpg'),
        require('../../assets/images/3.jpg'),
      ]
    }
  },
  {
    id: 2 ,
    title: 'mohamed1',
    img: require('../../assets/images/AboutUs2.png'),
    desc: '000',
    about:{
      desc:'here is desc',
      features:[
        'feature 1',
        'feature 2',
        'feature 3',
        'feature 4',
        'feature 5',
        'feature 6',
      ]
    }
  },
  {
    id: 3 ,
    title: 'mohamed2',
    img: require('../../assets/images/Card1.jpg'),
    desc: '000',
    about:{
      desc:'here is desc',
      features:[
        'feature 1',
        'feature 2',
        'feature 3',
        'feature 4',
        'feature 5',
        'feature 6',
      ]
    }
  },
  {
    id: 4 ,
    title: 'mohamed3',
    img: require('../../assets/images/Card2.jpg'),
    desc: '000',
    about:{
      desc:'here is desc',
      features:[
        'feature 1',
        'feature 2',
        'feature 3',
        'feature 4',
        'feature 5',
        'feature 6',
      ]
    }
  },
  {
    id: 5 ,
    title: 'mohamed4',
    img: require('../../assets/images/Card3.jpg'),
    desc: '000',
    about:{
      desc:'here is desc',
      features:[
        'feature 1',
        'feature 2',
        'feature 3',
        'feature 4',
        'feature 5',
        'feature 6',
      ]
    }
  },
  {
    id: 6 ,
    title: 'mohamed5',
    img: require('../../assets/images/about.jpg'),
    desc: '000',
    about:{
      desc:'here is desc',
      features:[
        'feature 1',
        'feature 2',
        'feature 3',
        'feature 4',
        'feature 5',
        'feature 6',
      ]
    }
  },
  {
    id: 7,
    title: 'mohamed5',
    img: require('../../assets/images/service1.jpg'),
    desc: '000',
    about:{
      desc:'here is desc',
      features:[
        'feature 1',
        'feature 2',
        'feature 3',
        'feature 4',
        'feature 5',
        'feature 6',
      ]
    }
  },
]