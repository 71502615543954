import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import React from 'react';
import {
    Row,
    Col,
    Alert,
    Form,
    Input,
    Label,
    Card,
    CardBody,
} from "reactstrap";

const EmailForm = () => {
    return (
        <Card className="shadow rounded border-0">
            <CardBody className="py-5">
                <h4 className="card-title">Get In Touch !</h4>
                <div className="custom-form mt-3">
                <div id="message"></div>
                <Alert
                    color="info"
                    // isOpen={}
                    toggle={() => {
                    
                    }}
                >
                    Contact details send successfully.
                </Alert>
                <Form
                    method="post"
                    // onSubmit={this.handleSubmit}
                    name="contact-form"
                    id="contact-form"
                >
                    <Row>
                    <Col md={6}>
                        <div className="mb-3">
                        <Label className="form-label">
                            Your Name <span className="text-danger">*</span>
                        </Label>
                        <div className="form-icon position-relative">
                            <i>
                            <FeatherIcon
                                icon="user"
                                className="fea icon-sm icons"
                            />
                            </i>
                        </div>
                        <Input
                            name="name"
                            id="name"
                            type="text"
                            className="form-control ps-5"
                            placeholder="Name :"
                            required
                        />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="mb-3">
                        <Label className="form-label">
                            Your Email{" "}
                            <span className="text-danger">*</span>
                        </Label>
                        <div className="form-icon position-relative">
                            <i>
                            <FeatherIcon
                                icon="mail"
                                className="fea icon-sm icons"
                            />
                            </i>
                        </div>
                        <Input
                            name="email"
                            id="email"
                            type="email"
                            className="form-control ps-5"
                            placeholder="Email :"
                            required
                        />
                        </div>
                    </Col>
                    <Col md={12}>
                        <div className="mb-3">
                        <Label className="form-label">Subject </Label>
                        <div className="form-icon position-relative">
                            <i>
                            <FeatherIcon
                                icon="book"
                                className="fea icon-sm icons"
                            />
                            </i>
                        </div>
                        <Input
                            name="subject"
                            id="subject"
                            type="text"
                            className="form-control ps-5"
                            placeholder="Subject :"
                            required
                        />
                        </div>
                    </Col>
                    <Col md={12}>
                        <div className="mb-3">
                        <Label className="form-label">Comments</Label>
                        <div className="form-icon position-relative">
                            <i>
                            <FeatherIcon
                                icon="message-circle"
                                className="fea icon-sm icons"
                            />
                            </i>
                        </div>
                        <textarea
                            name="comments"
                            id="comments"
                            rows="4"
                            className="form-control ps-5"
                            placeholder="Message :"
                        ></textarea>
                        </div>
                    </Col>
                    </Row>
                    <Row>
                    <Col sm={12} className="text-center">
                        <div className="d-grid">
                        <input
                            type="submit"
                            id="submit"
                            name="send"
                            className="submitBnt btn btn-primary btn-block"
                            value="Send Message"
                        />
                        </div>
                        <div id="simple-msg"></div>
                    </Col>
                    </Row>
                </Form>
                </div>
            </CardBody>
        </Card>
    );
}

export default EmailForm;
