import React, { Fragment } from "react";
import { Col, Card, CardBody } from "reactstrap";

const Boxes = ({ boxes = [] }) => {
  return (
    <Fragment>
      {
        boxes.map((box , index)=>{
          return(
            <Col lg="4" md="6" xs="12" key={`box_${index}`} className="mt-4 pt-2">
              <Card
                className="catagories text-center rounded border-0"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <CardBody>
                  <img
                    src={box.img}
                    className=" mb-3"
                    width={80}
                    height={80}
                    alt=""
                  />
                  <h5 className="text-dark">
                    {box.title}
                  </h5>
                  <p className="text-muted mb-0 mt-3">{box.desc}</p>
                </CardBody>
              </Card>
            </Col>
          )
        })
      }
    </Fragment>
    );
}

export default Boxes;
