import React, { Fragment } from 'react';
import { Container, Row, Col } from "reactstrap";
import Boxes from './Boxes';
import img1 from "../../assets/images/boxes1.svg"
import img2 from "../../assets/images/boxes2.svg"
import img3 from "../../assets/images/boxes3.svg"
import img4 from "../../assets/images/boxes4.svg"
import img5 from "../../assets/images/boxes5.svg"
import img6 from "../../assets/images/boxes6.svg"

const boxes = [
    {
        title: 'Live Chat' ,
        img : img1,
        desc : "If the distribution of letters and 'words' is random, the reader will not be distracted from making"
    },
    {
        title: 'Social' ,
        img : img2,
        desc : "If the distribution of letters and 'words' is random, the reader will not be distracted from making"
    },
    {
        title: 'Support Portal' ,
        img : img3,
        desc : "If the distribution of letters and 'words' is random, the reader will not be distracted from making"
    },
    {
        title: 'Phone Supports' ,
        img : img4,
        desc : "If the distribution of letters and 'words' is random, the reader will not be distracted from making"
    },
    {
        title: 'Tickets' ,
        img : img5,
        desc : "If the distribution of letters and 'words' is random, the reader will not be distracted from making"
    },
    {
        title: "FAQ's" ,
        img : img6,
        desc : "If the distribution of letters and 'words' is random, the reader will not be distracted from making"
    }
]
const KeyFeature = () => {
    return (
        <Fragment>
            <section className="section bg-light" style={{paddingBottom:'200px'}}>
                <Container>
                    <Row className="justify-content-center">
                        <Col className="col-12 text-center">
                            <div className="section-title mb-4 pb-2">
                                <h4 className="title mb-4">Key Features</h4>
                                <p className="text-muted para-desc mx-auto mb-0">Start working with <span className="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Boxes boxes={boxes} />
                    </Row>
                </Container>
            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg
                    viewBox="0 0 2880 250"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                    <path
                        d="M720 125L2160 0H2880V250H0V125H720Z"
                        fill="currentColor"
                    ></path>
                    </svg>
                </div>
            </div>
        </Fragment>
    );
}

export default KeyFeature;