import React, { Fragment } from 'react';
import NavBar from '../NavBar/NavBar';
import { setActiveNavbarElement } from '../../helpers/actions';
import Footer from '../Footer/Footer';
import Section from './Section';
import Cards from '../SharedComponents/Cards';

const  Services = ()=> {
    setActiveNavbarElement(3)

    const cards = [
      {
        id: 1,
        image: require ('../../assets/images/service1.jpg'),
        title: "Industrial Software Development",
        desc: 'Elevate industrial efficiency with our custom Industrial Software Development. Our tailored solutions optimize processes and boost productivity, ensuring seamless integration and real-time monitoring.'
      },
      {
        id: 2,
        image: require ('../../assets/images/service2.jpg'),
        title: "Cloud Solutions",
        desc: 'Empower your business with our Cloud Solutions. Access anytime, anywhere from your laptop or mobile. Experience secure, accessible, and cost-effective computing tailored to your needs'
      },
      {
        id: 3,
        image: require ('../../assets/images/service3.jpg'),
        title: "Custom Software Design",
        desc: 'Boost your business with our special Custom Software Design . We create unique solutions that precisely fit your business needs. Experience seamless workflows, optimal functionality, and a personalized touch to drive your success.'
      },
    ];

  return (
      <Fragment>
        <NavBar/>
        <Section />
        <Cards cards={cards}/>
        <Footer/>
      </Fragment>
  );
}

export default Services;